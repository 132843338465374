import { Component } from 'react';
import logo from './assets/img/logo/gladius_small.png';
import logoCenter from './assets/img/logo/gladius_center_logo.png';
import videoSrc from './assets/media-temp/video-bg/video_bg_main.mp4';
import jjinfedlogo from './assets/img/logo/Logo_JJIF.png';
import bjjlogo from './assets/img/logo/logo-ibjjf-vertical-WHITE-2f0eca828e1178f13116b07c579cead2.svg';
import $ from 'jquery';
import './App.css';
import "./assets/css/unify-core.css";
import "./assets/css/unify-components.css";
import "./assets/css/unify-globals.css";
import "./assets/vendor/bootstrap/bootstrap.min.css";
import "./assets/vendor/bootstrap/offcanvas.css";
import "./assets/vendor/icon-awesome/css/font-awesome.min.css";
import "./assets/vendor/cubeportfolio-full/cubeportfolio/css/cubeportfolio.min.css";
import "./assets/vendor/hamburgers/hamburgers.min.css";
import "./assets/vendor/fancybox/jquery.fancybox.css";
import "./assets/vendor/animate.css";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { i18n } from "./i18n";
import { Translation } from 'react-i18next';
import "./components/js/hs.core.js";
import "./components/js/hs.header.js";
import "./components/js/hs.dropdown.js";
import "./components/js/hs.hamburgers.js";
import "./components/js/hs.go-to.js";
import "./components/js/hs.popup.js";
import "./assets/vendor/cubeportfolio-full/cubeportfolio/js/jquery.cubeportfolio.js";
import "./components/js/hs.cubeportfolio.js";
import "./assets/vendor/fancybox/jquery.fancybox.js";
import { Schedule } from "./components/react/Schedule";
import { Classes } from './components/react/Classes';


export interface IAppstate {
  //selectedLanguage: string;
  photoGalleryData: IImageGalleryData[];
  phGalThemesData: string[];
}

export interface IAppProps {

}

export interface IImageGalleryData {
  category: string;
  images: Image[];
}

export interface Image {
  title: string;
  imagePath: string;
  thumbnail: string;
}
export class App extends Component<IAppProps, IAppstate> {

  private gallery: JSX.Element[] = [];

  constructor(props: IAppProps) {
    super(props);
    this.state = {
      photoGalleryData: [],
      phGalThemesData: []
    };
  }

  public componentDidMount() {
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');
    $.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
      afterOpen: function () {
        $(this).find('input[type="search"]').trigger("focus");
      }
    });
    $.HSCore.components.HSPopup.init('.js-fancybox-media', {
      helpers: {
        media: {},
        overlay: {
          css: {
            'background': 'rgba(255, 255, 255, .8)'
          }
        }
      }
    });
    this.getGalleryData();
    $.HSCore.components.HSGoTo.init('.js-go-to');
  }

  private getGalleryData = () => {
    fetch('data/photoGalleryData.json',
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    ).then(function (response) {
      return response.json();
    }).then((myJson) => {
      let themes: string[] = myJson.map((th: any) => th.category);
      let photoGalleryData: IImageGalleryData[] = myJson;
      photoGalleryData.forEach((cat: IImageGalleryData) => {
        cat.images.forEach((image) => {
          this.gallery.push(<div className={`cbp-item g-width-280 g-width-170--md ${cat.category}`}>
            <a className="cbp-caption cbp-lightbox info-v3-2 d-block g-parent g-mb-20" href={`${image.imagePath}`}
              data-title={`${cat.category}`}>
              <div className="cbp-caption-defaultWrap g-overflow-hidden">
                <img className="g-transform-scale-1_2--parent-hover g-transition-0_2 g-transition--ease-in" src={`${image.thumbnail}`} alt="234" />
              </div>
              <div className="cbp-caption-activeWrap g-theme-bg-blue-dark-v1-opacity-0_6">
                <div className="cbp-l-caption-alignCenter g-pa-15">
                  <div className="cbp-l-caption-body g-brd-around g-brd-white-opacity-0_2">
                    <strong className="text-uppercase g-absolute-centered g-letter-spacing-0_5 g-font-weight-700 g-font-size-11 g-color-white">View photos</strong>
                  </div>
                </div>
              </div>
            </a>
            <h4 className="text-uppercase g-font-weight-700 g-font-size-11">{cat.category}
              <span className="g-theme-color-gray-light-v2">(12 photos)</span></h4>
          </div>);
        });
      });
      this.setState({ photoGalleryData: myJson, phGalThemesData: themes });
      $.HSCore.components.HSCubeportfolio.init('.cbp');
    });
  }

  private _languageChange = (language: string): void => {
    i18n.changeLanguage(language);
  }

  public render(): JSX.Element {
    return (
      <div className='g-bg-black-gradient-opacity-v1'>
        <header id="js-header" className="u-header u-header--static u-header--show-hide u-header--change-appearance" data-header-fix-moment="500" data-header-fix-effect="slide">
          <div className="u-header__section u-header__section--dark g-bg-black g-transition-0_3 g-py-10" data-header-fix-moment-exclude="g-bg-black g-py-10" data-header-fix-moment-classNamees="g-bg-black-opacity-0_7 g-py-0">
            <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
              <Container>
                <a className="navbar-brand d-lg-none" href="#home"><img src={logo} alt="Gladius" height={"60px"} /></a>
                <Navbar.Toggle as="button" className='g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0' aria-controls="responsive-navbar-nav"
                  children={
                    <button className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                      <span className="hamburger hamburger--slider">
                        <span className="hamburger-box">
                          <span className="hamburger-inner"></span>
                        </span>
                      </span>
                    </button>} />
                <Navbar.Collapse id="responsive-navbar-nav" >
                  <Nav as="ul" className=" me-auto align-items-lg-center mx-auto text-uppercase g-font-weight-600 u-nav-hover-v1">
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#home" className="px-0">{t("home")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#about" className="px-0">{t("about")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#classes" className="px-0">{t("classes")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                    <Nav.Item as="li" className='nav-logo-item g-mx-40--lg'>
                      <Navbar.Brand href="#home" className='js-go-to d-none d-lg-block navbar-brand mr-0' data-type="static">
                        <img src={logo} alt="Gladius" height={"60px"} />
                      </Navbar.Brand>
                    </Nav.Item>
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#schedule" className="px-0">{t("schedule")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#gallery" className="px-0">{t("gallery")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                    <Nav.Item as="li" className='g-mx-20--lg'>
                      <Translation >
                        {(t) => <Nav.Link href="#contacts" className="px-0">{t("contacts")}</Nav.Link>}
                      </Translation>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
                <Nav.Item>
                  <div className="g-pos-abs g-top-18 g-right-65 g-pos-rel--lg g-top-0--lg g-right-0--lg g-width-80 g-pt-3--lg g-ml-30 g-ml-0--lg">
                    <i className="fa fa-globe g-font-size-14 g-color-white g-mr-3"> </i>
                    <a href="/#" id="languages-dropdown-invoker-2" className="g-color-white g-text-underline--none--hover" aria-controls="languages-dropdown-2" aria-expanded="false" data-dropdown-event="click" data-dropdown-target="#languages-dropdown-2"
                      data-dropdown-type="css-animation" data-dropdown-duration="300" data-dropdown-hide-on-scroll="false" data-dropdown-animation-in="fadeIn" data-dropdown-animation-out="fadeOut">{i18n.language}
                      <i className="fa fa-angle-down g-ml-3"></i>
                    </a>
                    <ul id="languages-dropdown-2" className="list-unstyled g-pos-abs g-left-0 g-bg-gray-dark-v1 g-width-160 g-pb-5 g-mt-12" aria-labelledby="languages-dropdown-invoker-2">
                      <li>
                        <a className="d-block g-color-white g-color-red--hover g-text-underline--none--hover g-py-5 g-px-20" href="/#" onClick={() => { return this._languageChange("English"); }}>English</a>
                      </li>
                      <li>
                        <a className="d-block g-color-white g-color-red--hover g-text-underline--none--hover g-py-5 g-px-20" href="/#" onClick={() => { return this._languageChange("Georgian") }}>Georgian</a>
                      </li>
                      <li>
                        <a className="d-block g-color-white g-color-red--hover g-text-underline--none--hover g-py-5 g-px-20" href="/#" onClick={() => { return this._languageChange("Russian") }}>Russian</a>
                      </li>
                    </ul>
                  </div>
                </Nav.Item>
              </Container>
            </Navbar>
          </div>
        </header>
        <section id="home" className="g-flex-centered g-bg-pos-center g-py-100" style={{ backgroundColor: 'black' }} data-calc-target="#js-header">
          <div className="container text-center g-z-index-1">
            <div className="u-bg-overlay_iiner">
              <div className="container g-color-white text-center g-bg-cover__inner g-py-170">
                {/* <svg className="mb-5" width="56px" height="56px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-78.000000, -19.000000)">
                      <g transform="translate(78.000000, 19.000000)">
                        <path d="M0,0 L19.2941176,0 L19.2941176,0 C23.7123956,-8.11624501e-16 27.2941176,3.581722 27.2941176,8 L27.2941176,27.2941176 L8,27.2941176 L8,27.2941176 C3.581722,27.2941176 5.41083001e-16,23.7123956 0,19.2941176 L0,0 Z" fill="#72c02c"></path>
                        <path d="M21.036662,24.8752523 L20.5338647,22.6659916 L20.3510293,22.6659916 C19.8533083,23.4481246 19.1448284,24.0626484 18.2255681,24.5095816 C17.3063079,24.9565147 16.2575544,25.1799779 15.0792761,25.1799779 C13.0376043,25.1799779 11.5139914,24.672107 10.5083918,23.6563498 C9.50279224,22.6405927 9,21.1017437 9,19.0397567 L9,8.02392554 L12.6109986,8.02392554 L12.6109986,18.4150692 C12.6109986,19.7050808 12.8750915,20.6725749 13.4032852,21.3175807 C13.9314789,21.9625865 14.7593086,22.2850846 15.886799,22.2850846 C17.3901196,22.2850846 18.4947389,21.8356188 19.2006901,20.9366737 C19.9066413,20.0377286 20.2596117,18.5318912 20.2596117,16.4191164 L20.2596117,8.02392554 L23.855374,8.02392554 L23.855374,24.8752523 L21.036662,24.8752523 Z"
                          fill="#FFFFFF"></path>
                      </g>
                    </g>
                  </g>
                </svg> */}
                <img className="mb-5" src={logoCenter} alt="Gladius" />
                <h2 className="g-font-weight-700 g-font-size-40 g-font-size-70--md text-uppercase">Welcome to GLADIUS</h2>
                <p className="g-font-size-24">JAPANESE AND BRAZILIAN JIU-JITSU</p>

                <div className="g-pos-abs g-bottom-30 g-left-0 g-right-0">
                  <a className="js-go-to btn u-btn-outline-white g-color-white g-bg-white-opacity-0_1 g-color-black--hover g-bg-white--hover g-font-weight-600 text-uppercase g-rounded-50 g-px-30 g-py-11" href="/#" data-target="#about"><i className="fa fa-angle-down"></i></a>
                </div>
              </div>
            </div>
            <video autoPlay muted loop id="myVideo">
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </section>
        <section id="about" className="g-py-100">
          <div className="text-uppercase text-center g-mb-70">
            <Translation>{(t) => <h2 className="d-inline-block g-letter-spacing-0_5 g-font-weight-700 g-font-size-18 g-brd-bottom g-brd-5 g-color-black-opacity-0_8 g-brd-primary g-pb-8 g-mb-20"> {t("about")}</h2>}</Translation>
            <p className="text-uppercase g-letter-spacing-3 g-font-size-12 g-theme-color-gray-light-v2 mb-0"></p>
          </div>
          <div className="container">
            <article className="row">
              {/* <!-- Article Content --> */}
              <div className="col-lg-6 g-mb-30">
                <header className="u-heading-v6-2 g-mb-20">
                  {/* <Translation >
                    {(t) => <h6 className="g-font-size-12 text-uppercase g-font-weight-600 g-pl-90">{t("about")}</h6>}
                  </Translation> */}
                  <h2 className="u-heading-v6__title g-color-gray-dark-v2 g-brd-primary text-uppercase g-font-weight-600 g-mb-15">We are Gladius</h2>
                </header>

                <div className="g-pl-90--sm">
                  <p className="lead g-mb-5">ჯიუ-ჯიცუს კლუბი „გლადიუსი“ გახლავთ ყველაზე დიდი და წარმატებული ჯიუ-ჯიცუს კლუბი საქართველოში, სადაც შეისწავლება იაპონური ჯიუ-ჯიცუ, ბრაზილიური ჯიუ-ჯიცუ და თავდაცვითი ჯიუ-ჯიცუ.
                    ჯიუ-ჯიცუში საქართველოს ყავს 2 მსოფლიო ჩემპიონი პროფესიონალებში და 3 ფერად ქამრებში. </p>


                  <div className="container">
                    <div className="g-overflow-hidden">
                      <div className="row text-center mx-0 g-ml-minus-1 g-mb-minus-1">
                        <div className="col-4 d-flex align-items-center g-brd-left g-brd-bottom g-theme-brd-gray-dark-v2 px-0">
                          <a className="w-100 g-py-30 g-px-15" target={"_blank"} rel="noreferrer" href="https://jjif.sport/">
                            <img className="img-fluid" src={jjinfedlogo} alt="Jiu-jitsu international federation" />
                          </a>
                        </div>
                        <div className="col-4 d-flex align-items-center g-brd-left g-brd-bottom g-theme-brd-gray-dark-v2 px-0">
                          <a className="w-100 g-py-30 g-px-15" target={"_blank"} rel="noreferrer" href="https://ibjjf.com/">
                            <img className="img-fluid" src={bjjlogo} alt=" 22 description" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <!-- End Article Content --> */}

              {/* <!-- Article Image --> */}
              <div className="col-lg-6 align-self-center">
                <img className="img-fluid w-100 u-shadow-v21 rounded" src="../../assets/img-temp/ourphoto.jpg" alt="We are gladius" />
              </div>
              {/* <!-- End Article Image --> */}
            </article>
          </div>
        </section>
        
        <section id="classes" className="g-py-100" >
          <Classes />
        </section>
        <section id="schedule" className="g-theme-bg-gray-dark-v1 g-py-100">
          <div className="text-uppercase text-center g-mb-70">
            <Translation>{(t) => <h2 className="d-inline-block g-color-white-opacity-0_8 g-letter-spacing-0_5 g-font-weight-700 g-font-size-18 g-brd-bottom g-brd-5 g-brd-primary g-pb-8 g-mb-20"> {t("Ourschedule")}</h2>}</Translation>
            <p className="text-uppercase g-letter-spacing-3 g-font-size-12 g-theme-color-gray-light-v2 mb-0"></p>
          </div>
          <Schedule />
        </section>
        <section id="gallery" className="g-py-100">
          <div className="container">
            <div className="text-uppercase text-center g-mb-70">
              <h2 className="d-inline-block g-color-white-opacity-0_8 g-letter-spacing-0_5 g-font-weight-700 g-font-size-18 g-brd-bottom g-brd-5 g-brd-primary g-pb-8 g-mb-20">Photo Gallery</h2>
              <p className="text-uppercase g-letter-spacing-3 g-font-size-12 g-theme-color-gray-light-v2 mb-0"></p>
            </div>
            <div className="row">
              <div className="col-md-10">
                <div id="topWorksCubePortfolio" className="cbp"
                  data-controls="#topWorksCubePortfolioFilter"
                  data-layout="grid"
                  data-animation="slideLeft"
                  data-caption-animation="fadeIn"
                  data-x-gap="30"
                  data-y-gap="80"
                  data-media-queries='[
                     {"width": 800, "cols": 4},
                     {"width": 500, "cols": 3},
                     {"width": 320, "cols": 1}
                   ]'>
                  {this.gallery}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contacts" className="container g-pt-100 g-pb-40">
          <div className="text-uppercase text-center g-mb-70">
            <Translation>{(t) => <h2 className="d-inline-block g-letter-spacing-0_5 g-font-weight-700 g-font-size-18 g-brd-bottom g-brd-5 g-color-white-opacity-0_8 g-brd-primary g-pb-8 g-mb-20"> {t("contacts")}</h2>}</Translation>
            <p className="text-uppercase g-letter-spacing-3 g-font-size-12 g-theme-color-gray-light-v2 mb-0"></p>
          </div>
          <div className="row justify-content-between">
            <div className="col-md-7 g-mb-60">
              <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/%E1%83%91%E1%83%A0%E1%83%90%E1%83%96%E1%83%98%E1%83%9A%E1%83%98%E1%83%A3%E1%83%A0%E1%83%98+%E1%83%AF%E1%83%98%E1%83%A3-%E1%83%AF%E1%83%98%E1%83%AA%E1%83%A3+%E1%83%92%E1%83%9A%E1%83%90%E1%83%93%E1%83%98%E1%83%A3%E1%83%A1%E1%83%98+Brazilian+Jiu-Jitsu+Gladius/@41.7089041,44.7839173,16z/data=!4m5!3m4!1s0x0:0xcc28024d06dc1d7b!8m2!3d41.7098163!4d44.7866226"><img className="img-fluid w-100 u-shadow-v21 rounded" src="../../assets/img-temp/jiujitsu_map.jpg" alt="Jui-Jutsu Gladius" /></a>
            </div>

            <div className="col-md-4">
              <div className="mb-4">
                <h2 className="h5 g-color-white-opacity-0_8 g-font-weight-600">Address:</h2>
                <p className="g-color-white-opacity-0_8 g-font-size-16">N6, Merab Kostavas I turn<br />Tbilisi<br />Georgia</p>
              </div>

              <div className="mb-4">
                <h2 className="h5 g-color-white-opacity-0_8 g-font-weight-600">Email us:</h2>
                <p className="g-color-white-opacity-0_8">Email: <a className="g-color-white-opacity-0_8" href="/#">jiujitsugladius@gmail.com</a>
                </p>
              </div>

              <div className="mb-3">
                <h2 className="h5 g-color-white-opacity-0_8 g-font-weight-600">Call us:</h2>
                <p className="g-color-white-opacity-0_8">Phone number: <span className="g-color-white-opacity-0_8">+995 557 78 88 81</span>
                </p>
              </div>

              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-bg-facebook--hover g-color-white--hover rounded-circle" target={"_blank"} rel="noreferrer" href="https://www.facebook.com/jiujitsugladius">
                    <i className="g-font-size-default fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-4">
                  <a className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-bg-instagram--hover g-color-white--hover rounded-circle" target={"_blank"} rel="noreferrer" href="https://www.instagram.com/jiujitsugladius/">
                    <i className="g-font-size-default fa fa-instagram"></i>
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </section >

        <a className="js-go-to u-go-to-v1" href="/#" data-type="fixed" data-position='{"bottom": 15,"right": 15}' data-offset-top="400" data-compensation="#js-header" data-show-effect="zoomIn">
          <i className="fa fa-arrow-up"></i>
        </a>
      </div >
    );
  }
}

export default App;
