import { Translation } from 'react-i18next';

export const Schedule = (): JSX.Element => {
      return (<div className="container">
      {/* <!-- Table Schedule --> */}
      <div className="table-responsive g-brd-around g-brd-primary g-brd-around-none--lg">
        <table className="table u-table--v2 text-center text-uppercase mb-0">
          <thead className="g-bg-primary">
            <tr className="g-letter-spacing-1 g-font-weight-700 g-font-size-12 g-color-white g-col-border-top-0">
              <th></th>
              <Translation >{(t) => <th>{t("Monday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Tuesday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Wednesday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Thursday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Friday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Saturday")}</th>}</Translation>
              <Translation >{(t) => <th>{t("Sunday")}</th>}</Translation>
            </tr>
          </thead>

          <tbody className="g-letter-spacing-1 g-font-weight-700 g-font-size-12 g-color-white-opacity-0_5 g-theme-bg-gray-dark-v2">
            <tr>
              <th className="g-color-white" scope="row">Kids E</th>
              <td>09:30 - 11:00 (JAP)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>09:30 - 11:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids B</th>
              <td><i className="fa fa-minus"></i></td>
              <td>18:30 - 20:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>18:30 - 20:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids A</th>
              <td>18:30 - 20:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>18:30 - 20:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids D</th>
              <td><i className="fa fa-minus"></i></td>
              <td>17:30 - 19:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>17:30 - 19:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids C</th>
              <td>17:30 - 19:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>17:30 - 19:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids F</th>
              <td>17:00 - 18:30 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>17:00 - 18:30 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids G</th>
              <td><i className="fa fa-minus"></i></td>
              <td>17:00 - 18:30 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>17:00 - 18:30 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids H</th>
              <td>18:30 - 20:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>18:30 - 20:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids I</th>
              <td><i className="fa fa-minus"></i></td>
              <td>19:30 - 21:00 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td>18:30 - 20:00 (Jap)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Kids K</th>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td>14:00 - 15:30 (Jap)</td>
              <td>13:00 - 14:30 (Jap)</td>
            </tr>
            <tr>
              <th className="g-color-white" scope="row">Adults</th>
              <td>20:00 - 21:30 (Bjj)</td>
              <td>20:00 - 21:30 (Bjj)</td>
              <td>20:00 - 21:30 (Jap. Krav Maga)</td>
              <td>20:00 - 21:30 (Bjj)</td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
              <td><i className="fa fa-minus"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <!-- End Table Schedule --> */}
      <div className="g-hidden-lg-up text-center">
        <span className="u-icon-v2 g-color-white shake g-brd-none rounded-0 p-0">
          <i className="fa fa-hand-pointer-o"></i>
        </span>
      </div>
    </div>);
  };