import { Translation } from 'react-i18next';

export const Classes = (): JSX.Element => {
    return (
        <div className="container">
            <div className="text-uppercase text-center g-mb-70">
                <Translation>{(t) => <h2 className="d-inline-block g-color-black-opacity-0_8 g-letter-spacing-0_5 g-font-weight-700 g-font-size-18 g-brd-bottom g-brd-5 g-brd-primary g-pb-8 g-mb-20"> {t("classes")}</h2>}</Translation>
                <p className="text-uppercase g-letter-spacing-3 g-font-size-12 g-theme-color-gray-light-v2 mb-0"></p>
            </div>

            <div className="row">
                <div className="col-lg-6 g-pl-100--md g-mb-30">
                    <article className="media d-block d-md-flex h-100 g-bg-white">
                        {/* <!-- Article Image --> */}
                        <div className="d-md-flex align-self-center g-mr-30--md g-ml-minus-82--md">
                            <a className="js-fancybox-media g-pos-rel" href="/#"
                                data-src="#popupVideo1"
                                data-animate-in="fadeIn"
                                data-animate-out="fadeOut"
                                data-speed="1000">
                                <img className="w-100" src="assets/img-temp/169x169/kravmaga.png" alt="2 description" />
                                <i className="fa fa-play fa-3x g-absolute-centered g-color-primary"></i>
                            </a>
                        </div>
                        <div id="popupVideo1" className="clearfix rounded-0 g-pa-30" style={{ display: "none", width: "800px", maxWidth: "100%" }}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <video className="js-video-audio u-video-v1 mb-0" preload='true' controls>
                                    <source src="/assets/videos/fs1.mp4" type="video/mp4;" />
                                </video>
                            </div>
                        </div>
                        {/* <!-- End Article Image --> */}

                        <div className="media-body align-self-center g-py-50 g-pl-40 g-pl-0--md g-pr-40">
                            <h3 className="h6 text-uppercase g-letter-spacing-4 g-font-weight-700 g-mb-20">ADULTS Japanese Jiu-Jitsu. Krav Maga</h3>
                            <p className="g-theme-color-gray-light-v2 mb-0">Built on simple principles, instinctive movements, and practical techniques, Krav Maga was made the official self-defense system of the Israel Defense Forces.</p>
                        </div>
                        {/* <!-- End Article Content --> */}
                    </article>
                </div>

                <div className="col-lg-6 g-pl-100--md g-mb-30">
                    <article className="media d-block d-md-flex h-100 g-bg-white">
                        <div className="d-md-flex align-self-center g-mr-30--md g-ml-minus-82--md">
                            <a className="js-fancybox-media g-pos-rel" href="/#"
                                data-src="#popupVideo2"
                                data-animate-in="fadeIn"
                                data-animate-out="fadeOut"
                                data-speed="1000">
                                <img className="w-100" src="assets/img-temp/169x169/adults-bjj.png" alt="2 description" />
                                <i className="fa fa-play fa-3x g-absolute-centered g-color-primary"></i>
                            </a>
                        </div>
                        <div id="popupVideo2" className="clearfix rounded-0 g-pa-30" style={{ display: "none", width: "800px", maxWidth: "100%" }}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <video className="js-video-audio u-video-v1 mb-0" preload='true' controls>
                                    <source src="/assets/videos/bjj.mp4" type="video/mp4;" />
                                </video>
                            </div>
                        </div>
                        {/* <!-- End Article Image --> */}

                        <div className="media-body align-self-center g-py-50 g-pl-40 g-pl-0--md g-pr-40">
                            <h3 className="h6 text-uppercase g-letter-spacing-4 g-font-weight-700 g-mb-20"> ADULTS Brazilian Jiu-Jitsu. BJJ</h3>
                            <p className="g-theme-color-gray-light-v2 mb-0">Brazilian Jiu-Jitsu is a self-defence martial art and combat sport based on grappling, ground fighting (ne-waza) and submission holds.</p>
                        </div>
                        {/* <!-- End Article Content --> */}
                    </article>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 g-pl-100--md g-mb-30">
                    <article className="media d-block d-md-flex h-100 g-bg-white">
                        <div className="d-md-flex align-self-center g-mr-30--md g-ml-minus-82--md">
                            <a className="js-fancybox-media g-pos-rel" href="/#"
                                data-src="#popupVideo3"
                                data-animate-in="fadeIn"
                                data-animate-out="fadeOut"
                                data-speed="1000">
                                <img className="w-100" src="assets/img-temp/169x169/kravmaga_kids.png" alt="2 description" />
                                <i className="fa fa-play fa-3x g-absolute-centered g-color-primary"></i>
                            </a>
                        </div>
                        <div id="popupVideo3" className="clearfix rounded-0 g-pa-30" style={{ display: "none", width: "800px", maxWidth: "100%" }}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <video className="js-video-audio u-video-v1 mb-0" preload='true' controls>
                                    <source src="/assets/videos/bjj.mp4" type="video/mp4;" />
                                </video>
                            </div>
                        </div>
                        {/* <!-- End Article Image --> */}

                        <div className="media-body align-self-center g-py-50 g-pl-40 g-pl-0--md g-pr-40">
                            <h3 className="h6 text-uppercase g-letter-spacing-4 g-font-weight-700 g-mb-20"> KIDS Japanese Jiu-Jitsu. Krav Maga</h3>
                            <p className="g-theme-color-gray-light-v2 mb-0">Krav maga for children isn't just safe; it does a world of good for their body and mind and lets them know how to solve trouble with the greatest of ease. If you're searching for a unique, exhilarating and rewarding discipline that will teach your kids valuable lessons as they head into the real world, look no further.</p>
                        </div>
                        {/* <!-- End Article Content --> */}
                    </article>
                </div>

                <div className="col-lg-6 g-pl-100--md g-mb-30">
                    <article className="media d-block d-md-flex h-100 g-bg-white">
                        {/* <!-- Article Image --> */}
                        <div className="d-md-flex align-self-center g-mr-30--md g-ml-minus-82--md">
                            <img className="w-100" src="assets/img-temp/169x169/kids-bjj.png" alt="p description" />
                        </div>
                        {/* <!-- End Article Image --> */}

                        <div className="media-body align-self-center g-py-50 g-pl-40 g-pl-0--md g-pr-40">
                            <h3 className="h6 text-uppercase g-letter-spacing-4 g-font-weight-700 g-mb-20">KIDS Brazilian Jiu-Jitsu. BJJ</h3>
                            <p className="g-theme-color-gray-light-v2 mb-0">Jiu-Jitsu teaches children valuable self-defence skills, and is great for both health and confidence. BJJ is an effective anti-bullying system. Not only are little champions able to defend themselves, they learn to do so in the safest way possible. They’ll create new social circles outside of school while doing so. Friendships made on the mats tend to last a very long time!</p>
                        </div>
                        {/* <!-- End Article Content --> */}
                    </article>
                </div>
            </div>
        </div>
    );
}