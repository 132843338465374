import i18next, { i18n as i18nInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './assets/locales/en/translation.json';
import translationGE from './assets/locales/ge/translation.json';
import translationRU from './assets/locales/ru/translation.json';

export const languages = {
  Georgian: "Georgian",
  English: "English",
  Russian: "Russian"
};

export const resources = {
  [languages.English]: {
    translation: translationEN
  },
  [languages.Georgian]: {
    translation: translationGE
  },
  [languages.Russian]: {
    translation: translationRU
  }
};

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance()
    .use(LanguageDetector)
    .use(initReactI18next);
    
    i18n.init({
    lng: language,
    fallbackLng: languages.English,
    resources: resources
  }, undefined);

  return i18n;
};

export const i18n = createI18n(languages.Georgian);